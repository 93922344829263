import React from 'react'
import { Link } from 'react-router-dom'
import { Stack, Button } from '@mui/material'
import { useSelector } from 'react-redux'
import { getSelectedProfile } from '~selectors/profile'
import { Select } from '~primitives/Input/Select'
import { SelectSector } from './SelectSector'
import { SelectSchool } from './SelectSchool'
import { makeFinalPath } from '../helpers'
import { Form } from '../styles'

const getKinds = (profile) => {
  if (profile.kind === 'school') return [{ id: 'school', name: 'Escola' }]
  else if (profile.kind === 'sector')
    return [
      { id: 'sector', name: 'Regional' },
      { id: 'school', name: 'Escola' },
    ]
  else if (profile.kind === 'district')
    return [
      { id: 'district', name: 'Rede' },
      { id: 'sector', name: 'Regional' },
      { id: 'school', name: 'Escola' },
    ]
}

export const SelectionForm = ({
  selected,
  onChange,
  params,
  examId,
  feature,
  resource,
}) => {
  const profile = useSelector(getSelectedProfile)
  const kinds = getKinds(profile)

  const selectedWithDefaults = {
    ...selected,
    districtId: profile?.personable?.district_id || 0,
  }

  return (
    <Form>
      <Select
        label="Nível"
        name="kind"
        background="white"
        options={kinds}
        disabled={kinds.length === 1}
        value={selected.kind}
        onChange={onChange}
      />

      {(selected.kind === 'sector' || selected.kind === 'school') && (
        <SelectSector selected={selected} onChange={onChange} params={params} />
      )}

      {selected.kind === 'school' && selected.sectorId && (
        <SelectSchool selected={selected} onChange={onChange} params={params} />
      )}

      <Stack direction="column" justifyContent="center">
        <Button
          component={Link}
          variant="primary"
          id="confirm-selection-button"
          data-testid="confirm-selection"
          to={makeFinalPath(selectedWithDefaults, {
            feature,
            resource,
            examId,
          })}
          disabled={selected.kind !== 'district' && !selected[`${selected.kind}Id`]}
        >
          Continuar
        </Button>

        <Button component={Link} variant="transparent" to="/pagina-inicial">
          Voltar para o inicio
        </Button>
      </Stack>
    </Form>
  )
}
