import React from 'react'
import { connect } from 'react-redux'
import FeaturesGroup from '..'
import { FeatureLink } from '../../FeatureLink'
import { getPath } from '~helpers/paths'
import { getSelectedProfile } from '~selectors/profile'

const mapStateToProps = (state) => ({ profile: getSelectedProfile(state) })

const adaptFeatures = (featuresArray) => {
  if (!Array.isArray(featuresArray)) return featuresArray

  const emptyFeature = {
    label: null,
    description: null,
    resources: [],
  }

  const features = featuresArray.reduce((acc, feature) => {
    if (!feature?.name) return acc

    acc[feature.name] = {
      label: feature.label || null,
      description: feature.description,
      resources: Array.isArray(feature.resources) ? feature.resources : [],
    }
    return acc
  }, {})

  // Garantindo que todas as features necessárias existam
  const requiredFeatures = [
    'retrospective',
    'enem',
    'indicators',
    'foco_exam',
    'curriculum',
    'management',
  ]

  requiredFeatures.forEach((feature) => {
    if (!features[feature]) {
      features[feature] = emptyFeature
    }
  })

  return features
}

const TeacherFeaturesGroup = ({
  profile,
  features,
  isShowingNewHome,
  activeCollapse,
  setActiveCollapse,
  activeMenu,
  setActiveMenu,
}) => {
  const defaultProps = {
    activeCollapse,
    setActiveCollapse,
    activeMenu,
    setActiveMenu,
  }

  const adaptedFeatures = adaptFeatures(features)

  const generateFeatureData = (featureData, feature) => {
    if (!Array.isArray(featureData)) return []

    return featureData.map(({ exam_id, name: resource, label, exam_type }) => ({
      label: label || '',
      href: getPath({
        feature,
        examType: exam_type,
        resource,
      })(exam_id, profile),
      feature,
      name: `${feature}-${name}`,
      resource: resource || '',
      id: exam_id || null,
    }))
  }

  const hasResources = (feature) =>
    (adaptedFeatures?.[feature]?.resources?.length || 0) > 0

  return (
    <>
      {(hasResources('retrospective') || hasResources('enem')) && (
        <FeaturesGroup
          title="Resultados da Escola"
          icon="menuSchoolResult"
          name="school_result"
          activeMatches={['retrospective', 'enem']}
          {...defaultProps}
          groups={[
            {
              title: 'Avaliações externas',
              features: [
                ...generateFeatureData(
                  adaptedFeatures?.retrospective?.resources,
                  'retrospective'
                ),
              ],
            },
            {
              title: 'Enem',
              features: [
                ...generateFeatureData(
                  adaptedFeatures?.enem?.resources,
                  'enem'
                ),
              ],
            },
          ]}
        />
      )}

      {hasResources('indicators') && (
        <FeaturesGroup
          title="Painel"
          icon="menuDashboard"
          name="indicators"
          {...defaultProps}
          activeMatches={['dashboard', 'painel']}
          groups={[
            {
              features: [
                ...generateFeatureData(
                  adaptedFeatures?.indicators?.resources?.filter(
                    (feature) =>
                      !(isShowingNewHome && feature?.resource === 'dashboard')
                  ),
                  'indicators'
                ),
              ],
            },
          ]}
        />
      )}

      {hasResources('foco_exam') && (
        <FeatureLink
          iconName="focoExamEditions"
          to={`/gerenciar-avaliacoes/${profile?.kind}/${profile?.personable?.id}`}
          text="Avaliações"
          isActive={location.pathname.includes('/gerenciar-avaliacoes')}
          onMouseOver={() => setActiveMenu('')}
        />
      )}

      {hasResources('curriculum') && (
        <FeaturesGroup
          title="Currículo"
          icon="menuCurriculum"
          name="curriculum"
          {...defaultProps}
          activeMatches={['curriculo']}
          groups={[
            {
              features: [
                ...generateFeatureData(
                  adaptedFeatures?.curriculum?.resources,
                  'curriculum'
                ),
              ],
            },
          ]}
        />
      )}

      {hasResources('management') && (
        <FeaturesGroup
          title="Cadastrar"
          icon="menuManagement"
          name="management"
          {...defaultProps}
          activeMatches={['gerenciar-turmas', 'gerenciar-calendario']}
          groups={[
            {
              features: [
                ...generateFeatureData(
                  adaptedFeatures?.management?.resources,
                  'management'
                ),
              ],
            },
          ]}
        />
      )}
    </>
  )
}

export default connect(mapStateToProps, {})(TeacherFeaturesGroup)
