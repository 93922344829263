import React from 'react'
import { omit } from 'ramda'
import { Select } from '~primitives/Input/Select'
import Filters from '~resources/filters'
import useResource from '~hooks/useResource'
import { FlashMessage } from '~primitives/Messages/FlashMessage'

export const SelectSector = ({ selected, onChange, params }) => {
  const { error, loading, data } = useResource({
    resource: Filters.getSelectionSectors,
    params: omit(['sectorId', 'schoolId'], params),
  })

  if (error) {
    return (
      <FlashMessage color="danger">
        Houve um erro ao carregar regionais
      </FlashMessage>
    )
  }

  return (
    <Select
      withSearch
      label="Regional"
      name="sectorId"
      background="white"
      options={data?.sectors ?? []}
      value={selected.sectorId}
      onChange={onChange}
      disabled={loading}
      placeholder={loading ? 'Carregando...' : 'Selecione'}
    />
  )
}
