import { handleApiError } from '../Network'
import { track } from '~resources/ahoy'
import Auth from '~resources/auth'
import Users from '~resources/users'

const updateLogin = (response) => ({
  type: 'UPDATE_LOGIN',
  payload: response,
})

const logout = () => ({ type: 'LOGOUT' })

export const updateProfile = (data) => ({
  type: 'UPDATE_PROFILE',
  payload: data,
})

export const updateUser = (data) => ({
  type: 'UPDATE_USER',
  payload: data,
})

export const removeToken = () => (dispatch) => {
  track('Logout')
  Auth.logout().then(() => dispatch(logout()))
}

export const fetchAuth = (params) => (dispatch) =>
  Auth.login(params).then(([error, response]) => {
    if (error) {
      track('Login / Error', {
        email: params.email,
        error_code: error.status,
        error_message: error.message,
      })
    }

    if (response) {
      if (params.googleidtoken) {
        track('Login / Sucess with Google Auth')
      } else {
        track('Login / Success', { email: params.email })
      }

      dispatch(
        updateLogin({
          token: response['access-token'],
          type: response['token-type'],
          client: response.client,
          expiry: response.expiry,
          uid: response.uid,
        })
      )
    }

    return [error, response]
  })

export const selectProfile = (profile_id) => {
  track('Select Profile', { profile_id })

  return {
    type: 'SELECT_PROFILE',
    payload: profile_id,
  }
}

export const fetchProfiles = () => (dispatch) =>
  Users.getProfiles().then(([error, response]) => {
    if (error) dispatch(handleApiError(error))

    if (response) {
      dispatch({ type: 'GET_PROFILE_LIST', payload: response.profiles })
      dispatch({ type: 'GET_USER_DATA', payload: response.user })

      if (response.profiles.length === 1)
        dispatch(selectProfile(response.profiles[0].id))
    }
  })

export const fetchFeatures = () => (dispatch) =>
  Users.getFeatures().then(([error, response]) => {
    if (error) dispatch(handleApiError(error))

    if (response) {
      dispatch({ type: 'GET_FEATURES', payload: response.features })
    }
  })

export const fetchStudentFeatures = () => (dispatch) =>
  Users.getStudentFeatures().then(([error, response]) => {
    if (error) dispatch(handleApiError(error))

    if (response) {
      dispatch({ type: 'GET_STUDENT_FEATURES', payload: response })
    }
  })

export const editUser = (params) => (dispatch) =>
  Users.patchUser(params).then(([error, data]) => {
    if (data) {
      dispatch(updateUser(data))
      track('User / Settings / Submit Success', params)
    }

    if (error) {
      track('User / Settings / Submit Error', params)
    }

    return [error, data]
  })

export const editProfile = (params) => (dispatch) =>
  Users.patchProfile(params).then(([error, data]) => {
    if (data) {
      dispatch(updateProfile(data))
      track('User / Settings / Submit Success', params)
    }

    if (error) {
      track('User / Settings / Submit Error', params)
    }

    return [error, data]
  })

export default {
  removeToken,
  fetchAuth,
  selectProfile,
  fetchProfiles,
  fetchFeatures,
  fetchStudentFeatures,
  editUser,
  editProfile,
}
