import React, { useState } from 'react'
import _ from 'lodash'
import { track } from '~resources/ahoy'
import { Container, ContainerHeader, FormHeader, FeatureIcon } from './styles'
import { Heading } from '~primitives/Typography'
import { SVGIcon } from '~primitives/Utilities/SVGIcon'
import useProfileData from '~hooks/useProfileData'
import { getSelectedProfile } from '~selectors/profile'
import { useSelector } from 'react-redux'
import { SelectionForm } from './SelectionForm'

const Selection = ({ examId, feature, resource }) => {
  const [selected, setSelected] = useState({
    kind: undefined,
    groupType: undefined,
    sectorId: undefined,
    schoolId: undefined,
  })

  const profile = useSelector(getSelectedProfile)

  const params = {
    ...selected,
    districtId: profile.personable.district_id,
    entityType: profile.kind,
    entityId: profile.personable.id,
    feature,
    resource,
  }

  const { getFeature, getResource } = useProfileData()

  const featureLabel = getFeature(feature).label
  const resourceLabel = getResource(feature, resource, examId)?.label

  const handleChange = ({ name, value }) => {
    track(`Selection / Selected ${name}`, {
      feature,
      resource,
      examId,
      value,
      current_selection: selected,
    })

    if (name === 'sectorId') {
      setSelected((currentSelected) => ({
        ...currentSelected,
        [name]: value,
        schoolId: undefined,
      }))
    } else {
      setSelected((currentSelected) => ({ ...currentSelected, [name]: value }))
    }
  }

  return (
    <Container>
      <ContainerHeader>
        <FeatureIcon feature={feature}>
          <SVGIcon name={_.camelCase(feature)} color="textNegative" size={7} />
        </FeatureIcon>

        <Heading as="h2" size={3}>
          {featureLabel} | <strong>{resourceLabel}</strong>
        </Heading>
      </ContainerHeader>
      <FormHeader>
        <Heading as="h3" size={3} center>
          Selecione a origem dos dados que deseja visualizar
        </Heading>
      </FormHeader>

      <SelectionForm
        selected={selected}
        onChange={handleChange}
        params={params}
        examId={examId}
        feature={feature}
        resource={resource}
      />
    </Container>
  )
}

export default Selection
