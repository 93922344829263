import React from 'react'
import { omit } from 'ramda'
import { Select } from '~primitives/Input/Select'
import Filters from '~resources/filters'
import useResource from '~hooks/useResource'
import { FlashMessage } from '~primitives/Messages/FlashMessage'

export const SelectSchool = ({ selected, onChange, params }) => {
  const { error, loading, data } = useResource({
    resource: Filters.getSelectionSchools,
    params: omit(['schoolId'], params),
  })

  if (error) {
    return (
      <FlashMessage color="danger">
        Houve um erro ao carregar escolas
      </FlashMessage>
    )
  }

  return (
    <Select
      withSearch
      label="Escola"
      name="schoolId"
      background="white"
      options={
        data?.schools?.map((school) => ({
          ...school,
          name: `${school.name} (${school.id})`,
        })) ?? []
      }
      value={selected.schoolId}
      onChange={onChange}
      disabled={loading}
      placeholder={loading ? 'Carregando...' : 'Selecione'}
    />
  )
}
