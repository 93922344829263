import { useSelector } from 'react-redux'

const useProfileData = () => {
  const { features } = useSelector((state) => state.user)

  const getFeature = (featureName) => {
    return features?.find((f) => f.name === featureName)
  }

  const getResource = (featureName, resourceName, exam_id) => {
    if (!features?.find((f) => f.name === featureName)) return

    const resource = features
      ?.find((f) => f.name === featureName)
      .resources.find(({ resource, id }) =>
        exam_id
          ? id === Number(exam_id) && resource === resourceName
          : resource === resourceName
      )

    return resource
  }

  const profileHasFeature = (featureName, resourceName) =>
    !!getResource(featureName, resourceName)

  return { getFeature, getResource, profileHasFeature }
}

export default useProfileData
