import { any, filter, map, omit, pipe } from 'ramda'
import store from '~store'

const profileHasFeature = (feature, resource_name) => {
 const features = store.getState().user.features
 const featureData = features?.find(f => f.name === feature)
 if (!featureData) return false

 return !!featureData.resources.find(
   ({ resource }) => resource === resource_name
 )
}

const profileHasAnyFeature = any(({ feature, requiredResource }) =>
 profileHasFeature(feature, requiredResource)
)

export const filterStepsByFeatures = (steps) =>
 pipe(
   filter(({ requiredResources }) => {
     if (!requiredResources) return true
     return profileHasAnyFeature(requiredResources)
   }),
   map(omit(['requiredResources']))
 )(steps)